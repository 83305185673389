function header() {
  //Add Class by open Menu
  $('header .navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).add('html').toggleClass('hidden');
    $(this).add('body').toggleClass('hidden');
  });

  $('.navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $('#main-menu').toggleClass("open");
  });

  $("#main-menu").find("a").click(function(e) { 
    $('#main-menu').toggleClass("open");
    $('.navbar-toggler').toggleClass("open");
    $(this).add('html').toggleClass('hidden');
    $(this).add('body').toggleClass('hidden');
  });

  //ADD CLASS BY SCROLLING

  $(window).scroll(function(){
    var st = $(window).scrollTop();
    if ( st > 0 ) {
      $('header').addClass('fixed');
    } else {
      $('header').removeClass('fixed');
    }
  });

  var st = $(window).scrollTop();
  if ( st > 0 ) {
    $('header').addClass('fixed');
  } else {
    $('header').removeClass('fixed');
  }

  var st = $(window).scrollTop();
  if ( st > 0 ) {
    $('header').addClass('fixed');
  } else {
    $('header').removeClass('fixed');
  }

  // Prüfe, ob der Benutzer auf der Startseite ist
if (window.location.pathname === '/') {

} else {
  // Der Benutzer ist auf einer Unterseite, füge Event-Listener zu den Menü-Links hinzu
  const menu = document.querySelector('nav');
  const menuLinks = menu.querySelectorAll('a');

  menuLinks.forEach(link => {
    link.addEventListener('click', function(event) {
      event.preventDefault(); // Verhindere den Standard-Link-Klick
      const targetUrl = window.location.protocol + "//" + window.location.host + '/#' + link.getAttribute('href').substring(1); // Ziel-URL für Startseite mit Ankerpunkt
      window.location.href = targetUrl; // Leite den Benutzer zur Startseite mit Ankerpunkt
    });
  });
}
}




export { header };


// -- Cache selectors
function activeState() {
  if ($(window).width() > 767) {
    var lastId,
      topMenu = $("#main-menu"),
      topMenuHeight = topMenu.parent().parent().outerHeight() + 150,
      // -- All list items

      menuItems = topMenu.find("a"),
      // -- Anchors corresponding to menu items

      scrollItems = menuItems.map(function () {
        var item = $($(this).attr("href"));

        if (item.length) {
          return item;
        }
      });
  } else {
    var lastId,
      topMenu = $("#main-menu"),
      topMenuHeight = topMenu.parent().parent().outerHeight() + 75,
      // -- All list items

      menuItems = topMenu.find("a"),
      // -- Anchors corresponding to menu items

      scrollItems = menuItems.map(function () {
        var item = $($(this).attr("href"));

        if (item.length) {
          return item;
        }
      });
  }

  // -- Bind click handler to menu items

  // -- so we can get a fancy scroll animation

  menuItems.click(function (e) {
    var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;

    menuItems.removeClass("active");

    $(this).addClass("active");

    // console.log(href);

    // if (screen.width < 992) {
    // $(".navbar-collapse").collapse("hide");
    $(".navbar-collapse").removeClass("show");

    $(".navbar-backdrop").removeClass("show");

    $(".navbar-toggler").removeClass("collapsed");

    $(".navbar-toggler").attr("aria-expanded", "false");
    // }

    $("html, body").stop().animate(
      {
        scrollTop: offsetTop,
      },
      100,
      "swing"
    );

    e.preventDefault();
  });

  // -- Bind to scroll

  $(window).scroll(function () {
    // -- Get container scroll position

    var fromTop = $(this).scrollTop() + topMenuHeight;

    // -- Get id of current scroll item

    var cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop) return this;
    });

    // -- Get the id of the current element

    cur = cur[cur.length - 1];

    var id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
      lastId = id;

      // -- Set/remove active class

      menuItems.removeClass("active");

      menuItems
        .end()
        .find("a[href='#" + id + "']")
        .addClass("active");
    }
  });
}

export { activeState };

