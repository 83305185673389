import owlCarousel from "owl.carousel";

function teaserslider() {
	var checkWidth = $(window).width();
	var owlPost = $(".teaser-slider");
	if (checkWidth > 1128) {
	  if (typeof owlPost.data('owl.carousel') != 'undefined') {
		owlPost.data('owl.carousel').destroy();
	  }
	  owlPost.removeClass('owl-carousel');
	} else if (checkWidth < 1128) {
	  owlPost.addClass('owl-carousel');
	  owlPost.owlCarousel({
		items: 3,
		loop: true,
		margin: 30,
		lazyLoad: true,
		lazyLoadEager: 2,
		autoplay: false,
		nav: true,
		navText: [
		'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><path d="M492,236H68.442l70.164-69.824c7.829-7.792,7.859-20.455,0.067-28.284c-7.792-7.83-20.456-7.859-28.285-0.068    l-104.504,104c-0.007,0.006-0.012,0.013-0.018,0.019c-7.809,7.792-7.834,20.496-0.002,28.314c0.007,0.006,0.012,0.013,0.018,0.019    l104.504,104c7.828,7.79,20.492,7.763,28.285-0.068c7.792-7.829,7.762-20.492-0.067-28.284L68.442,276H492    c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/></svg>',
		'<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068    c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557    l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104    c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"/></svg>',
		],
		responsive: {
			0: {
			  items: 1,
			},
			630: {
			  items: 2,
			},
			970: {
			  items: 3,
			},
		  },
	  });
	}
}

export { teaserslider };
