function accordion() {
  for (let i = 1; i <= 100; i++) {
    $(".accordion-heading-" + i).on("click", function (e) {

      $(this).toggleClass("active");

      $(".accordion-heading")
        .not(this)
        .removeClass("active");

      if ($(".accordion-body-" + i).css("overflow") != "hidden") {
        $(".accordion-body")
          .not($(this).siblings(".accordion-body-" + i))
          .slideUp();

        $(this).next(".accordion-body").slideToggle();
      }

    });
  }
}

export { accordion };
