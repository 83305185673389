/* General + Third-Party */
import jQuery from 'jquery';
import "owl.carousel";
import { lazyload } from './globals/lazyload';

/* Layout-Parts */
import { header } from './layout/header';
import { activeState } from './layout/header';

/* Blocks */
import { checkInput } from '../../blocks/form/script';
import { video } from '../../blocks/text-media/script';
import { gallery } from '../../blocks/gallery/script';
import { accordion } from '../../blocks/accordion/script';
import { teaserslider } from '../../blocks/teaser-slider/script';
import { showPopup } from '../../blocks/employee/script';

jQuery(document).ready(_ => {
  // General + Third-Party
  lazyload();

  //Layout-Parts
  header();
  activeState();

  //Blocks
  checkInput();
  video();
  gallery();
  accordion();
  teaserslider();
  showPopup();
});

$(window).resize(teaserslider);
