function showPopup() {
  $(".team-item").click(function () {
    $(this).add('html').addClass('hidden');
    var popup = $(this).find("#popup-modal");

    popup.addClass('show');

    popup.click(function (event) {
      event.stopPropagation();
      $(this).removeClass('show');
      $(this).add('html').removeClass('hidden');
    });
  });
}

export { showPopup };


